'use client';

import { cn } from '@streetferret/utils';
import { useCallback, useEffect, useState } from 'react';

type NavProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  scrollThreshold?: number;
};
export function Nav({ children, scrollThreshold = 5, ...props }: NavProps) {
  const scrolled = useScroll(scrollThreshold);
  return (
    <nav
      {...props}
      className={cn(
        'sticky top-0 z-50 transition-all py-2 px-0',
        // for the PWA folks https://developer.mozilla.org/en-US/docs/Web/CSS/env
        'h-[calc(env(safe-area-inset-top,0px)_+_55px)]',
        {
          ['border-b bg-background/75 backdrop-blur-lg']: scrolled,
        },
        props.className,
      )}>
      {children}
    </nav>
  );
}

function useScroll(threshold: number) {
  const [scrolled, setScrolled] = useState(false);

  const onScroll = useCallback(() => {
    setScrolled(window.scrollY > threshold);
  }, [threshold]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  // also check on first load
  useEffect(() => {
    onScroll();
  }, [onScroll]);

  return scrolled;
}
