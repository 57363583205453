'use client';
import { Button } from '@streetferret/ui-web/button';
import { Divider } from '@streetferret/ui-web/divider';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@streetferret/ui-web/sheet/sheet';
import { cn } from '@streetferret/utils';
import { Menu } from 'lucide-react';
import Link from 'next/link';
import { useSelectedLayoutSegments } from 'next/navigation';
import { createContext, useContext, useState } from 'react';
import { navItems } from '../_lib/nav-items';

interface SideNavContext {
  close?: () => void;
}
const sideNavContext = createContext<SideNavContext>({});

export interface SideNavProps {
  className?: string;
}
export function SideNav({ className }: SideNavProps) {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);

  const segments = useSelectedLayoutSegments();
  const activeSegment = segments.length === 0 ? 'home' : segments[0];

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className={cn('!px-1 gap-1', className)}
          size={'sm'}>
          <span className="sr-only">navigation menu</span>
          <Menu className="h-8 w-8" />
        </Button>
      </SheetTrigger>
      <SheetContent>
        <sideNavContext.Provider value={{ close }}>
          <SheetHeader>
            <SheetTitle>Run Across Texas</SheetTitle>
          </SheetHeader>
          <Divider className="my-2" />
          <div className="flex flex-col justify-start -mx-2.5">
            {navItems.map(link => (
              <SideNavItem
                active={link.segment === activeSegment}
                href={link.link}
                key={link.link}>
                <div className="flex gap-2 items-center">
                  {link.icon}
                  <span className="my-3">{link.label}</span>
                </div>
              </SideNavItem>
            ))}
          </div>
        </sideNavContext.Provider>
      </SheetContent>
    </Sheet>
  );
}

export function SideNavItem({
  href,
  children,
  disabled,
  active,
}: {
  href: string;
  children: React.ReactNode;
  disabled?: boolean;
  active?: boolean;
}) {
  const { close } = useContext(sideNavContext);
  return (
    <Button
      key={href}
      variant={active ? 'default' : 'ghost'}
      className="!text-left !justify-between !flex"
      asChild>
      {disabled ? (
        // this hack is to get disabled to work
        <Button className="flex grow" disabled variant={'ghost'}>
          {children}
        </Button>
      ) : (
        <Link href={href} onClick={close}>
          {children}
        </Link>
      )}
    </Button>
  );
}
