'use client';
import { type NavItem } from '@/components/nav/nav-types';
import { Button } from '@streetferret/ui-web/button';
import Link from 'next/link';
import { useSelectedLayoutSegments } from 'next/navigation';
import { navItems, type NavSegment } from '../_lib/nav-items';

export function TopNav() {
  return (
    <div className="hidden items-center gap-2 md:flex">
      {navItems
        .filter(link => link.link !== '/')
        .filter(link => link.link !== '/donate')
        .filter(link => link.link !== '/track')
        .map(item => (
          <NavItemLink item={item} key={item.link} />
        ))}
    </div>
  );
}

function NavItemLink({ item }: { item: NavItem }) {
  const segments = useSelectedLayoutSegments();
  const activeSegment = segments.length === 0 ? 'home' : segments[0];

  return (
    <Button
      variant={activeSegment === item.segment ? 'secondary' : 'ghost'}
      size={'sm'}
      asChild
      key={item.link}>
      <Link href={item.link}>{item.label}</Link>
    </Button>
  );
}

export function NavLink({ segment }: { segment: NavSegment }) {
  return (
    <NavItemLink item={navItems.find(item => item.segment === segment)!} />
  );
}
