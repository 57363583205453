import type { AppRouter } from '@streetferret/api';
import { httpLink, loggerLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
import superjson from 'superjson';
import { getTrpcApiUrl } from './trpc-url';

/**
 * Client side trpc instance. Use this to make queries to the StreetFerret TRPC API (packages/api) over REST in the browser.
 */
export const api = createTRPCReact<AppRouter>({});

export const trpcClient = api.createClient({
  transformer: superjson,
  links: [
    loggerLink({
      enabled: opts =>
        process.env.NODE_ENV === 'development' ||
        (opts.direction === 'down' && opts.result instanceof Error),
    }),
    httpLink({
      url: getTrpcApiUrl(),
    }),
  ],
});
/**
 * Inference helpers for input types
 * @example type HelloInput = RouterInputs['example']['hello']
 **/
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helpers for output types
 * @example type HelloOutput = RouterOutputs['example']['hello']
 **/
export type RouterOutputs = inferRouterOutputs<AppRouter>;
