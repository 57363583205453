import React from 'react';
import { type Unit } from '@streetferret/geospatial/conversions';

interface PreferencesContextProps {
  /**
   * The preferred unit system to display data in.
   */
  unit: Unit;
}

export const UnitPreferenceContext =
  React.createContext<PreferencesContextProps>({
    unit: 'imperial',
  });

interface UnitPreferenceProviderProps extends PreferencesContextProps {
  children: React.ReactNode;
}

/**
 * Provider to set the unit preference for the app.
 * @returns
 */
export function UnitPreferenceProvider({
  children,
  unit,
}: UnitPreferenceProviderProps) {
  return (
    <UnitPreferenceContext.Provider value={{ unit }}>
      {children}
    </UnitPreferenceContext.Provider>
  );
}

/**
 * Hook to get the unit preference for the app and current user.
 * @returns
 */
export function useUnitPreference() {
  const context = React.useContext(UnitPreferenceContext);
  if (context === undefined) {
    throw new Error(
      'useUnitPreference must be used within a UnitPreferenceProvider',
    );
  }
  return context;
}

export { Unit };
